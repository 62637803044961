body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#gallery .img-div {
  position: relative;
  display: inline-block;
}
#gallery .img-div:hover:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background: url("./img/instaicon.png") center no-repeat;
  background-size: 28px;
  opacity: 0.8;
}

#gallery .img-div:hover img {
  filter: brightness(0.65);
  transition: filter 700ms ease;
}

/* input[type="date"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
} */
