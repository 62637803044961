.my-card{
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px;
    width: 300px;
    margin-bottom: 60px;
    border: none;
    border: 0px;
    box-shadow:0 3px 6px 0 rgba(0, 0, 0, 0.082),
        0 3px 6px 0 rgba(0, 0, 0, 0.111) ;

}
.my-title{
    margin-bottom: 60px;
    margin-left: -30px;
        margin-right: -40px;
        border-radius: 100%;
        width: 225px;
        height: 225px;
        z-index: 50;
        overflow: hidden;
        text-align: start;    
        
}
.title{
    border-radius: 50%;
  
    width: 220px;
        height: 220px;
}
.card{
    border: 0px;
    text-align: start;
}