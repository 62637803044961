@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-alice-carousel/lib/alice-carousel.css";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Playfair+Display:wght@400;500;600&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;
}

#akin-chat-button {
  bottom: 20px !important;
  /* background-color: #008dbb !important; */
  padding: 10px !important;
}

.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2d637a;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: brightness(70%);
}

@media (max-width: 768px) {
  .hero::before {
    background: url(./img/cover.webp);
  }
}

.hero ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.booking {
  background: rgba(0, 0, 0, 0.7);
}

.locationslider {
  width: 85%;
  height: 80%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationslider .swiper-slide img {
  display: block;
  width: 100%;
  height: 60%;
  object-fit: cover;
  z-index: 6;
}

.swiper-button-next,
.swiper-button-prev {
  display: none;
  color: #fff;
  color: #fff !important;
  fill: fff !important;
  stroke: fff !important;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.testimonials::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./img/testimonials.webp);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: brightness(70%);
}

.CaptionUsername {
  display: none !important;
}

.css-6kluu6 {
  display: none !important;
}

/*  */

.rewards-spin-game {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.canvas-container {
  background-color: #2c8af7;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  min-height: 450px;
  overflow: hidden;
  position: relative;
  min-width: 450px;
}
#wheel {
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(1, 1, 1, 0.3);
  height: 400px;
  left: 0;
  position: absolute;
  margin: 21px;
  top: 0;
  transition: transform 4s cubic-bezier(0.5, -0.08, 0.5, 1.04);
  width: 400px;
}

#spin {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: #a72810;
  text-transform: uppercase;
  text-transform: uppercase;
  font-size: 20px;
  color: var(--white);
  width: 60px;
  height: 60px;
  font-family: sans-serif;
  border-radius: 50%;
  outline: none;
  letter-spacing: 1px;
  cursor: pointer;
}

.arrow {
  position: fixed;
  top: 25.6%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
  color: #f3cd35 !important;
}

.wheel-div {
  background-color: rgba(255, 255, 255, 0.9) !important;
  background-image: url("https://static.vecteezy.com/system/resources/thumbnails/003/031/750/small/dark-blue-wide-background-with-radial-blurred-gradient-vector.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* .wheel-button {
  background-image: url("./img/wheel.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
} */

.vertical {
  writing-mode: vertical-lr;
}

#wheel {
  border: 5px solid #181818;
  zoom: 1;
  color: #000 !important;
}

.canvas-container {
  background: #181818;
  color: #181818 !important;
  box-shadow: 0 0 10px rgb(31, 30, 30);
  border: 5px solid #f3cd35;
  opacity: 100%;
  font-family: dm;
}

@media (max-width: 768px) {
  .canvas-container {
    zoom: 0.75;
  }
}

#spin {
  background: #f3cd35;
  color: black;
  border: 3px solid #181818;
  font-weight: 600;
  padding: 10px;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
}

.swiper-button-next {
  display: none;
}

@media (max-width: 768px) {
  .swiper-button-next {
    display: block !important;
    margin-top: -40px !important;
  }
  .swiper-button-prev {
    display: block !important;
    margin-top: -40px !important;
  }
}
/* 
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
} */

input[type="date"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

.down-arrow {
  position: absolute;
  top: calc(100vh - 60px);
  left: calc(50% - 14px);
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 2px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

.down-arrow {
  animation: jumpInfinite 1.5s infinite;
}

.shadow-box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.blogContent p {
  margin-bottom: 10px !important;
  line-height: 32px;
  font-size: 18px;
}

.event-card {
  filter: drop-shadow(-10px 10px 0px #116b89);
}

.yogaHero {
  background: url("./img/yoga.webp");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 12px;
  padding: 12px;
}

.launchPadHero {
  background: url("./img/launchpadHero.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 12px;
  padding: 12px;
}

.launchpadHerotext {
  position: absolute;
  top: 66vh !important;
  left: 3vw !important;
}

.launchpadHerotext p {
  color: black !important;
}

.quote {
  font-weight: bolder !important;
  font-size: xx-large !important;
  margin-top: -15px !important;
}

@media screen and (max-width: 768px) {
  .launchPadHero {
    background-position: right;
    -webkit-background-postion: revert;
  }

  .launchpadHerotext {
    top: 49vh !important;

    margin: 3vh;
  }
  .launchpadHerotext p {
    color: white !important;
  }

  .launchPadHero h1 {
    background-color: #f9f871 !important;
  }

  .quote {
    margin-top: -6px !important;
  }
}

.manaliHero {
  background: url("./img/manaliHero.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 12px;
  padding: 12px;
}
.varkalaHero {
  background: url("./img/Varkala-img.webp");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 12px;
  padding: 12px;
}

@media (max-width: 768px) {
  .yogaHero {
    background-position: 70% 80%;
  }
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.marquee {
  background-color: #a74b14;
  color: white;
  font-size: large;
  font-weight: 800;
  z-index: 50;
  top: 0;
  padding: 3px;
}

.shimmer {
  /* text-align: center; */
  color: rgba(255, 255, 255, 0.6);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0f0),
    to(#0cf),
    color-stop(0.5, #0f0)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#0f0),
    to(#0cf),
    color-stop(0.5, #0f0)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#0f0),
    to(#0cf),
    color-stop(0.5, #0f0)
  );
  -webkit-background-size: 125px 100%;
  -moz-background-size: 125px 100%;
  background-size: 125px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}

@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

.scribble {
  background-image: url("./img/scribble.png");
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #f9f871;
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: #f9f871;
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

#speakers .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

#speakers-pc .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 500px !important;
  height: 500px;
}

#speakers-pc .swiper-slide img {
  display: block;
  width: 100%;
}

#speakers-pc .swiper-pagination-bullet-active {
  background-color: #2d6a87 !important;
}

#speakers-mobile {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
body {
  background-color: black;
  color: white;
  align-content: center;
  justify-content: center;
  font-family: Montserrat;
}
*::selection {
  color: #a6f;
}
p {
  position: absolute;
  top: 70%;
  left: 50%;
  translate: -50% -50%;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: white;
  font-size: 2vw;
  font-family: Space Grotesk;
  transition: transform 0.3s ease;
}

p:hover {
  transform: scale(1.4);
}

h1 {
  transition: transform 2s ease;
  font-weight: 100;
  position: absolute;
  top: 30%;
  left: 50%;
  translate: -50% -50%;
  width: 70vw;
  font-family: "DM Sans", sans-serif;
  font-size: 5vw;
}
h1:hover {
  transition: transform 5s ease;
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  #speakers-pc {
    display: none;
  }

  #speakers-mobile {
    display: block;
  }
}
